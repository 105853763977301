<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="customerPlanGrid"
          ref="customerPlanGridRef"
          width="inherit"
          :height="setHeight"
          :data-source="customerPlanDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                ref="btnEdit"
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit Product"
                @click="editSelectedItem(data)"
              />
            </div>
          </template>
          <dx-column data-field="companyName" caption="Company Name" alignment="left" />
          <dx-column data-field="planCategory" caption="Category" :width="150" alignment="center" :customize-text="customizePlanCategoryText" />
          <dx-column data-field="planType" caption="Plan Type" alignment="left" />
          <dx-column data-field="planName" caption="Plan Name" alignment="left" />
          <dx-column data-field="itemFee" :width="100" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="min" caption="Min" :width="100" alignment="right" />
          <dx-column data-field="max" caption="Max" :width="100" alignment="right" />
          <dx-column data-field="discount" caption="Discount" :width="100" alignment="right" />
          <dx-column data-field="status" alignment="center" :width="140" cell-template="statusTemplate" />
          <template #statusTemplate="{ data }">
            <div class="badge text-capitalize px-1 badge-pill" :class="getStatusFormatted(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="warehouse" caption="Warehouse" alignment="center" />
          <dx-column data-field="enrollmentDate" caption="Enroll Date" :width="120" alignment="center" data-type="date" />
          <dx-column data-field="startDate" caption="Start Date" :width="120" alignment="center" data-type="date" />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanCategory"
                  :data-source="planCategories"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanType"
                  :data-source="planTypes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedStatus"
                  :data-source="statusList"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
        <div class="col-md-12 px-0">
          <dx-util-popup
            ref="updatePlanPopup"
            :show-close-button="true"
            :drag-enabled="false"
            :close-on-outside-click="true"
            :show-title="true"
            content-template="content"
            title="Update Plan"
            width="400"
            height="auto"
          >
            <dx-util-position at="center" my="center" />
            <template #content>
              <customer-plan-update :key="rerenderKey" :current-row="selectedRow" @update-closed="reloadAfterUpdate" @closed="closePopup" />
            </template>
          </dx-util-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import planManagementService from '@/http/requests/plan/planManagementService'
import shipService from '@/http/requests/ship/shipService'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import customerPlanStatusEnum, { getCustomerPlanStatusList, getCustomerPlanStatusOptions } from '@/enums/customerPlanStatus.enum.js'
import customerPlanCategoryEnum, { getCustomerPlanCategoryList } from '@/enums/customerPlanCategory.enum.js'
import { customerPlanDataSource } from './customerPlanStore'

export default {
  components: {
    'customer-plan-update': () => import('./CustomerPlanUpdate.vue'),
  },
  mixins: [GridBase],
  data: () => ({
    customerPlanDataSource,
    accountNo: '',
    planCategories: getCustomerPlanCategoryList(),
    selectedPlanCategory: customerPlanCategoryEnum.ALL.value,
    planEnrollment: {},
    planTypes: [],
    selectedPlanType: 0,
    selectedPlanId: null,
    selectedStatus: customerPlanStatusEnum.RESERVED_ALL.value,
    statusList: getCustomerPlanStatusList(),
    statusOptions: getCustomerPlanStatusOptions(),
    warehouses: [],
    selectedWarehouse: 0,
    selectedRow: {},
    rerenderKey: 0,
  }),
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const grid = this.$refs.customerPlanGridRef.instance
      return grid
    },
  },
  mounted() {
    this.initialLoad()
    this.getWarehouses()
    this.getPlanTypes()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'bi bi-pencil-square',
          text: 'PLANS',
          type: 'success',
          onClick: () => {
            this.onClickPlans()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    initialLoad() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.status = this.selectedStatus
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.status = customerPlanStatusEnum.RESERVED_ALL.value
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
      this.selectedPlanCategory = customerPlanCategoryEnum.ALL.value
      this.selectedPlanType = 0
      this.selectedWarehouse = 0
      this.accountNo = ''
      this.dataGrid.clearFilter('search')
    },
    customizePlanCategoryText(cellInfo) {
      return `${cellInfo.value.toUpperCase()}`
    },
    getStatusFormatted(status) {
      if (status.toLowerCase() === 'active') return 'bg-success'
      if (status.toLowerCase() === 'passive') return 'bg-dark'
      if (status.toLowerCase() === 'pending') return 'bg-warning'
      if (status.toLowerCase() === 'rejected') return 'bg-danger'
      return 'bg-primary'
    },
    searchByFilter() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.accountNo = this.accountNo
      PlanSearchFilter.planType = this.selectedPlanType
      PlanSearchFilter.warehouseId = this.selectedWarehouse
      PlanSearchFilter.planCategory = this.selectedPlanCategory
      PlanSearchFilter.status = this.selectedStatus
      PlanSearchFilter.visibility = 8 // all(public and private)
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    getPlanTypes() {
      this.planTypes.splice(0, this.planTypes.length)
      this.planTypes.push({ value: 0, text: 'Plan Type (All)' })
      planManagementService.fetchAllPlanTypes().then(result => {
        result.data.forEach(element => {
          this.planTypes.push({ value: element.id, text: element.typeName })
        })
      })
    },
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        const { data } = result
        if (data.length > 1) {
          this.warehouses.push({ value: 0, text: 'Warehouse (All)' })
        }
        result.data.forEach(element => {
          if (element.name.toLowerCase() !== 'common') {
            this.warehouses.push({ value: element.id, text: element.name })
          }
        })
        this.selectedWarehouse = 0
        if (this.warehouses.length === 1) {
          this.selectedWarehouse = this.warehouses[0].value
        }
      })
    },
    onClickPlans() {
      this.$router.push({ name: 'route-plans-plan-management' })
    },
    editSelectedItem(e) {
      this.rerenderKey += 1
      this.selectedRow = e.row.data
      this.$refs.updatePlanPopup.instance.show()
    },
    reloadAfterUpdate() {
      this.$refs.updatePlanPopup.instance.hide()
      this.selectedRow = {}
      this.reload()
    },
    closePopup() {
      this.$refs.updatePlanPopup.instance.hide()
      this.selectedRow = {}
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
